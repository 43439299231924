
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.control-password-viewer {
  input {
    &:-webkit-autofill {
      ~ .input-group-append {
        .password-viewer-toggle {
          -webkit-box-shadow: 0 0 0 1000px theme-color-level('primary', -11) inset;
        }
      }
    }
  }

  .password-viewer-toggle {
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
