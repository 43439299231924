
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$controldatepicker-calendar-grid-btn-margin: 2px !default;
$controldatepicker-calendar-grid-btn-size: 24px !default;
$controldatepicker-calendar-grid-btn-font-size: $font-size-sm !default;

.control-datepicker {
  .b-form-btn-label-control.form-control {
    > .btn {
      padding-left: 0;
    }

    > .dropdown-menu {}

    .b-calendar {
      .b-calendar-inner {
        min-width: 0;
        width: 100% !important;

        .b-calendar-grid-body {
          .col {
            &[data-date] {
              .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: $controldatepicker-calendar-grid-btn-margin;
                padding: 0;
                width: $controldatepicker-calendar-grid-btn-size;
                height: $controldatepicker-calendar-grid-btn-size;
                font-size: $controldatepicker-calendar-grid-btn-font-size;
              }
            }
          }
        }
      }
    }
  }
}
