
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.evalanche-form {
  .form-content {
    position: relative;

    .fade-out {
      position: relative;

      &:after {
        content: '';
        position: absolute;

        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.4);
      }
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }
  }
}
