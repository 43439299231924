
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile

$controlsform-actions-gap: $spacer * 0.5 !default;
$controlsform-actions-button-gap: $spacer * 0.125 !default;

$controlsform-controls-input-inline-min-width: auto !default;
$controlsform-controls-textarea-inline-min-width: 280px !default;
$controlsform-controls-select-inline-min-width: auto !default;

// tablet

$controlsform-tablet: $tablet-breakpoint !default;

.controls-form {
  .form-busy {
  }

  .form-controls {
  }

  .form-actions {
    margin-top: $controlsform-actions-gap;

    > div {
      display: flex;
      align-items: center;
      margin: $controlsform-actions-button-gap * -1;

      .btn {
        margin: $controlsform-actions-button-gap;
      }
    }
  }

  &.is-inline {
    max-width: 100%;

    .form-controls {
      .control-input {
        min-width: $controlsform-controls-input-inline-min-width;
      }

      .control-textarea {
        min-width: $controlsform-controls-textarea-inline-min-width;
      }

      .control-select {
        min-width: $controlsform-controls-select-inline-min-width;
      }
    }

    .form-actions {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up($controlsform-tablet) {
    .form-controls {
      [class*='col-md-12'] {
        .form-group {
          .input-group {
            max-width: calc(50% - ($form-grid-gutter-width * 0.5));
          }
        }
      }
    }
  }
}
