
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

@import '~@trevoreyre/autocomplete-vue/dist/style.css';

.autocomplete {
  .autocomplete-input {
    max-width: initial !important;

    font-size: 1rem;
    line-height: 1.5;
    border-radius: 3px;
    height: calc(1.5em + 0.876rem + 4px);
    font-weight: 400;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.54);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      box-shadow: none;
    }
  }

  .autocomplete-result-list {
    border: 1px solid rgba(0, 0, 0, 0.54);

    .autocomplete-result {
      background-image: none;
    }
  }

  &[data-position=above] {
    .autocomplete-input[aria-expanded=true] {
      border-radius: 0 0 3px 3px;
    }

    .autocomplete-result-list {
      border-bottom-color: transparent;
      border-radius: 3px 3px 0 0;
    }
  }

  &[data-position=below] {
    .autocomplete-input[aria-expanded=true] {
      border-radius: 3px 3px 0 0;
    }

    .autocomplete-result-list {
      border-top-color: transparent;
      border-radius: 0 0 3px 3px;
    }
  }
}
